<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <products-list-add-new
      :is-add-new-products-sidebar-active.sync="isAddNewProductsSidebarActive"
      :suppliers-options="suppliersOptions"
      @refetch-data="refetchProductsData"
    />
    <template>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <template>
            <b-card
              no-body
            >
              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Entries</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-col>
                  <!-- Add -->
                  <b-col
                    class="d-flex align-items-center justify-content-end"
                    cols="12"
                    md="6"
                  >
                    <b-button
                      v-if="$can('update', 'product')"
                      class="mr-2"
                      variant="secondary"
                      @click="syncTariffs"
                    >
                      Synchroniseer tarieven
                    </b-button>
                    <b-button
                      v-if="$can('create', 'product')"
                      variant="primary"
                      @click="isAddNewProductsSidebarActive = true"
                    >
                      Toevoegen
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-table
                ref="refProductsListTable"
                :items="fetchProducts"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                show-empty
                empty-text="Geen overeenkomende records gevonden"
                class="position-relative products-table"
              >
                <template #cell(id)="data">
                  <b-link
                    :to="{ name: 'apps-products-edit', params: { id: data.item.id }}"
                    class="font-weight-bold"
                  >
                    #{{ data.value }}
                  </b-link>
                </template>
                <template #cell(name)="data">
                  <b-link
                    :to="{ name: 'apps-products-edit', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    <b-media
                      vertical-align="center"
                    >
                      <template #aside>
                        <a
                          href="/"
                          class="b-avatar router-link-active badge-secondary rounded-circle"
                          target="_self"
                          style="width: 32px; height: 32px; padding:2px"
                        >
                          <span
                            v-if="data.item.isChargingPointProposition"
                            class="b-avatar-text"
                            style="font-size: 20px"
                          >
                            <span
                              style="font-size: 20px"
                              class="fas fa-charging-station"
                            />
                          </span>
                          <span
                            v-if="!data.item.isChargingPointProposition && (data.item.type === 'ELECTRICITY' || data.item.type === 'DUAL')"
                            class="b-avatar-text"
                            style="font-size: 20px"
                          >
                            <span
                              style="font-size: 20px"
                              class="fas fa-bolt"
                            />
                          </span>
                          <span
                            v-if="!data.item.isChargingPointProposition && (data.item.type === 'GAS' || data.item.type === 'DUAL')"
                            class="b-avatar-text"
                            style="font-size: 20px"
                          >
                            <span
                              style="font-size: 20px"
                              class="fas fa-burn"
                            />
                          </span>
                        </a>
                      </template>
                      {{ data.item.name }}

                    </b-media>
                  </b-link>
                </template>
                <template #cell(createTimestamp)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">{{ formatDateTime(data.value) }}</span>
                  </div>
                </template>
                <!-- Column: Status -->
                <template #cell(status)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveProductStatusVariant(data.item)}`"
                    class="text-capitalize"
                  >
                    {{ resolveProductStatusName(data.item) }}
                  </b-badge>
                </template>
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <b-link
                      :id="`product-view-action-row-${data.item.id}`"
                      :to="{ name: 'apps-products-edit', params: { id: data.item.id }}"
                    >
                      <feather-icon
                        :id="`product-row-${data.item.id}-preview-icon`"
                        class="cursor-pointer mx-1 feather-hover"
                        icon="EyeIcon"
                        size="16"
                      />
                    </b-link>
                    <b-tooltip
                      :target="`product-view-action-row-${data.item.id}`"
                      placement="left"
                    >
                      <p class="mb-0">
                        Aanpassen
                      </p>
                    </b-tooltip>
                    <b-link
                      :id="`product-copy-action-row-${data.item.id}`"
                      @click="copyProduct(data.item.id,data.item.name)"
                    >
                      <feather-icon
                        :id="`product-row-${data.item.id}-copy-icon`"
                        class="cursor-pointer"
                        icon="CopyIcon"
                        size="16"
                      />
                    </b-link>
                    <b-tooltip
                      :target="`product-copy-action-row-${data.item.id}`"
                      placement="left"
                    >
                      <p class="mb-0">
                        Kopieren
                      </p>
                    </b-tooltip>
                  </div>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>

                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalProducts"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </template>
        </b-col>
        <b-col
          cols="12"
          md="6"
          xl="6"
          lg="6"
        />
      </b-row>
    </template>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BLink,
  BMedia,
  BOverlay,
  BTooltip,
  BBadge, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { formatDateTime } from '@core/utils/utils'
import useProductsList from '@/views/pages/products/useProductsList'
import productsStoreModule from '@/views/pages/products/productsStoreModule'
import ProductsListAddNew from '@/views/pages/products/products-add/ProductsListAddNew.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ProductsListAddNew,
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BMedia,
    BLink,
    vSelect,
    BOverlay,
    BTooltip,
    BBadge,
    BPagination,
  },
  data() {
    return {
      suppliersOptions: [],
    }
  },
  mounted() {
    this.fetchSuppliers()
  },
  methods: {
    copyProduct(id, name) {
      this.$swal({
        title: 'Maak een kopie van?',
        text: `${name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('apps-products-list/duplicateProduct', { id })
            .then(() => {
              this.refetchProductsData()
              this.$swal({
                icon: 'success',
                title: `${name}`,
                text: 'Is met succes gekopieerd!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: `Error bij het kopieren van product: ${name}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    syncTariffs() {
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const product of this.productsCallback) {
        this.syncProductPrices(product.id)
      }

      this.refetchProductsData()

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'De prijzen synchronisatie is getriggerd',
          icon: 'InfoIcon',
          variant: 'success',
        },
      })
    },
    syncProductPrices(id) {
      store.dispatch('apps-products-list/syncProductPrices', { id })
    },
    fetchSuppliers() {
      store.dispatch('apps-products-list/fetchSuppliers', {
        sortBy: 'name',
        sortDesc: false,
      })
        .then(response => {
          this.suppliersOptions = response.data.items
        })
        .catch(() => {
          this.suppliersOptions = []
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het ophalen van de labels',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    confirmProductsDeleteText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, verwijder het!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('apps-products-list/deleteProducts', { id })
            .then(() => {
              this.refetchProductsData()
              this.$swal({
                icon: 'success',
                title: 'Verwijderd!',
                text: 'Het product is verwijderd.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'apps-products-list'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, productsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const isAddNewProductsSidebarActive = ref(false)

    const {
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalCategories,
      dataMeta,
      perPageOptions,
      sortBy,
      refProductsListTable,
      refetchProductsData,
      resolveProductStatusVariant,
      resolveProductStatusName,
      isSortDirDesc,
      showOverlay,
      productsCallback,
      products,
      totalProducts,
    } = useProductsList()

    return {
      isAddNewProductsSidebarActive,
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalCategories,
      dataMeta,
      perPageOptions,
      sortBy,
      refProductsListTable,
      refetchProductsData,
      resolveProductStatusVariant,
      resolveProductStatusName,
      avatarText,
      isSortDirDesc,
      showOverlay,
      formatDateTime,
      productsCallback,
      products,
      totalProducts,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }

  .products-table{
    .description{
      width:30%;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
